import axios from 'axios';


const defaultTimeZone  =()=>{
    const currentTime = new Date();
    const timeZone = "Asia/Jerusalem"
    return { currentTime,timeZone};
}

export const getTimeZoneFromSite = async (p_relevant_url) =>{
    try {
        const relevantTimeZone = await axios.get(p_relevant_url + `/admin/get_site_time_zone`);
        if (relevantTimeZone.data !== 'err') {
            const { currentTime,timeZone} = relevantTimeZone.data;
            return { currentTime,timeZone};
        } else {
            return defaultTimeZone();
        }

    } catch (error) {
        console.log("error getTimeZoneFromSite " + p_relevant_url + ": " + error);
        return defaultTimeZone();
     
    }
}

/**
 * Asynchronously retrieves time zone information for a list of sites with a prioritized relevant site.
 * 
 * @param {string[]} site_list - An array of site names.
 * @param {string} p_relevant_site - The relevant site name.
 * @param {function} setRelevantTimeZone - The function to set relevant time zone data.
 * @returns {Promise<object>} - An object containing time zone information for each site.
 */
export const getTimeZoneFromAllSites = async (site_list, p_relevant_site, setRelevantTimeZone) => {
    let object = {};


    // Construct the URL based on the relevant site name.
    let relevant_url;
    if (p_relevant_site !== 'localhost') {
        relevant_url = "https://" + p_relevant_site + '.carteav.com';
    } else {
        relevant_url = "http://localhost:8080";
    }

    try {
        const relevantTimeZone = await axios.get(relevant_url + `/admin/get_site_time_zone`);

        if (relevantTimeZone.data !== 'err') {
            object[p_relevant_site] = relevantTimeZone.data;
            setRelevantTimeZone(relevantTimeZone.data);
        } else {
            object[p_relevant_site] = null;
        }

    } catch (error) {
        object[p_relevant_site] = null;
        console.log("error getTimeZoneFromAllSites " + p_relevant_site + ": " + error);
    }

    // Continue fetching data for the remaining sites.
    for (let site of site_list) {
        if (site === p_relevant_site){ 
            continue; // Skip the relevant site, as it's already handled.
        }

        let url;
        if (site !== 'localhost') {
            url = "https://" + site + '.carteav.com';
        } else {
            url = "http://localhost:8080";
        }

        try {
            const siteTimeZone = await axios.get(url + `/admin/get_site_time_zone`);

            if (siteTimeZone.data !== 'err') {
                object[site] = siteTimeZone.data;
            } else {
                object[site] = null;
            }

        } catch (error) {
            object[site] = null;
            console.log("error getTimeZoneFromAllSites " + site + ": " + error);
        }
    }

    return object;
};
