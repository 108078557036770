import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import useAuth from '../../../hooks/Auth/useAuth';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import stylesModule from "./event.module.css";
import CartCameras from '../../cartCameras/cartCameras';
import { Box, Modal } from '@mui/material';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


export default function BlockEventDialog({
    openDialog, 
    setOpenDialog,
    assisCartEventObject,
    setAssistCartEventObject
}){

    let {relevantUrl,relevantSite,sitesSockectsConnection} = useAuth();
    let cartId = assisCartEventObject.cartId;
    let eventName = assisCartEventObject.eventName;
    let blockedImg = assisCartEventObject.blockedImg;

    const handleClose = () => {
        setOpenDialog(false);
        setAssistCartEventObject(null);
    };

    const makeActionDialog = (action) => {
        axios.post(relevantUrl + `/admin/orderCartTodoAction`, { action: action, cartId: cartId })
        .then((res) => {

            Swal.fire({
                customClass: {
                    container: 'my-swal'
                },
                title: 'Action sent to cart!',
                text: 'Now cart should start performing your action...',
                icon: 'success',
                confirmButtonText: 'ok',
                position:'center',
                confirmButtonColor:'#00A7EE',
            })
        })
        .catch(()=>[
            Swal.fire({
                customClass: {
                    container: 'my-swal'
                },
                title: 'Something went wrong',
                text: 'Please try again .',
                icon: 'warning',
                confirmButtonText: 'ok',
                position:'center',
                confirmButtonColor:'#00A7EE',
            })
        ])
        handleClose();
    };


    return (
        <div >
          <Modal
            open={openDialog}
            onClose={handleClose}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box 
                sx={{ bgcolor: '#fff', borderRadius: '8px', p: 2 }}
            >
                {`Cart ${cartId} : ${eventName}`}
                <CartCameras cartId={cartId} handleClose={handleClose} />
                <div className={stylesModule.centeredActions}>
                    <button 
                        className={stylesModule.blockedEventActionButton}  
                        onClick={() => makeActionDialog("action_change_bounds_left")} 
                    >
                        ByPass (Left)
                    </button>  
                    <button 
                        className={stylesModule.blockedEventActionButton}  
                        onClick={() => makeActionDialog("action_change_bounds_right")} 
                    >
                        ByPass (Right)
                    </button>
                     <button 
                        className={stylesModule.blockedEventActionButton}  
                        onClick={() => makeActionDialog("action_ignore")} 
                    >
                        Ignore Obs
                    </button>
                    <button 
                        className={stylesModule.blockedEventActionButton}  
                        onClick={() => makeActionDialog("action_calc_new")} 
                    >
                        Calc New Path
                    </button>
                 </div>
            </Box>
          </Modal>
        </div>
      );
}







