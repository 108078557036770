import React from 'react';
import styles from "./dialog.module.css";

function LaodingDialog({text}) {
  return (
    <div className={styles.loader_container} >
                        
        <div className={styles.lds_roller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div 
            style={{color:"black",marginTop:'50px',fontWeight:'bold',textAlign:'center'}}
        >
        
            Please wait <br />
            {text}
        </div>
    </div>
  )
}

export default LaodingDialog