module.exports = {
    passenger_type_enum: {
        REGULAR: 'regular',
        ADVANCED: 'advanced',
        TECHNICAL: 'technical'
    },

    mission_type_enum: {
        MISSION_TYPE_NONE: 'MISSION_TYPE_NONE',
        MISSION_TYPE_TRANSPORTATION: 'MISSION_TYPE_TRANSPORTATION',
        MISSION_TYPE_CHARGING: 'MISSION_TYPE_CHARGING',
        MISSION_TYPE_SHUTTLE:'MISSION_TYPE_SHUTTLE'
    },

    mission_task_enum: {
        MISSION_TASK_NONE: 'MISSION_TASK_NONE',
        MISSION_TASK_TRANSPORTATION_GOTOPICKUP: 'MISSION_TASK_TRANSPORTATION_GOTOPICKUP',
        MISSION_TASK_TRANSPORTATION_PICKUP: 'MISSION_TASK_TRANSPORTATION_PICKUP',
        MISSION_TASK_TRANSPORTATION_GOTODEST: 'MISSION_TASK_TRANSPORTATION_GOTODEST',
        MISSION_TASK_TRANSPORTATION_FINISHED: 'MISSION_TASK_TRANSPORTATION_FINISHED',
        MISSION_TASK_CHARGING_GOTO_STATION: 'MISSION_TASK_CHARGING_GOTO_STATION',
        MISSION_TASK_CHARGING_CONNECTING: 'MISSION_TASK_CHARGING_CONNECTING',
        MISSION_TASK_CHARGING_CHARGING: 'MISSION_TASK_CHARGING_CHARGING',
        MISSION_TASK_CHARGING_FINISHED: 'MISSION_TASK_CHARGING_FINISHED',
    },

    task_type:{
        TRIGGERED_TASK_WAIT:'WaitTask',
        TRIGGERED_TASK_SMS:'SmsTask',
        TRIGGERED_TASK_PLAY_SOUND:'PlaySoundTask',
    },

    path_type_enum: {
        PATH_TYPE_NONE: 'PATH_TYPE_NONE',
        PATH_TYPE_TRANSPORTATION_GOTOPICKUP: 'PATH_TYPE_TRANSPORTATION_GOTOPICKUP',
        PATH_TYPE_TRANSPORTATION_PICKUP: 'PATH_TYPE_TRANSPORTATION_PICKUP',
        PATH_TYPE_TRANSPORTATION_GOTODEST: 'PATH_TYPE_TRANSPORTATION_GOTODEST',
        PATH_TYPE_TRANSPORTATION_FINISHED: 'PATH_TYPE_TRANSPORTATION_FINISHED',
        PATH_TYPE_CHARGING_GOTO_STATION: 'PATH_TYPE_CHARGING_GOTO_STATION',
        PATH_TYPE_CHARGING_CONNECTING: 'PATH_TYPE_CHARGING_CONNECTING',
        PATH_TYPE_CHARGING_CHARGING: 'PATH_TYPE_CHARGING_CHARGING',
        PATH_TYPE_CHARGING_FINISHED: 'PATH_TYPE_CHARGING_FINISHED',
        PATH_TYPE_SHUTTLE_GOTO_DEST: 'PATH_TYPE_SHUTTLE_GOTO_DEST',
        PATH_TYPE_SHUTTLE_GOTO_START_POINT: 'PATH_TYPE_SHUTTLE_GOTO_START_POINT',
        PATH_TYPE_SHUTTLE_ARRIVEDTODEST: 'PATH_TYPE_SHUTTLE_ARRIVEDTODEST',
        PATH_TYPE_SHUTTLE_FINISHED: 'PATH_TYPE_SHUTTLE_FINISHED',
    },
    path_status_enum:{
        NONE:"NONE",
        READY:"READY",// path received, ready to drive
        DRIVING:"DRIVING", 
        PAUSED:"PAUSED",  //not driving, but has a path and ready to conyinue
        FINISHED:"FINISHED",  //path done, last point aquired
        STOPPED:"STOPPED", //USER STOPPED
        ERROR:"ERROR", //error occoursed, can't drive
        BLOCKED:"BLOCKED" ,//error occoursed, can't drive
        BLOCKED_STABLE:"BLOCKED_STABLE",  //BLOCKED for x (configurable) time
    },
    event_type_enum:{
        ROAD_EVENT:"road_event",
        VOICE_EVENT:"voice_assistance_event",
        MISSED_CALL_EVENT:"missed_call_event",
        MULFUNCTION_EVENT:"cart_mulfunction_event",
        CART_UNAVAILABILITY_EVENT:"cart_uavailable_event",
        GENERAL_EVENT:"general_event"
    },
    event_severity_enum:{
        INFO:'info',
        WARNING:'warning',
        ERROR:'error',
    },
    mapping_app_point_type_enum: {
        POI: 'POI',
        MEMO: 'MEMO',
        STOP_LINE: 'STOP_LINE',
        BOUNDARY: 'BOUNDARY',
        ROAD: 'RAOD',
        CROSSWALK:'CROSSWALK'
    }
}