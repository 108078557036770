import useAuth from "../../../hooks/Auth/useAuth"
import { ROLES } from "../../../redux/context/allowedTypes";
export default function ResponsiveBodyContainer({children}){
    let {auth ,role} = useAuth();
 

    const isAdmin = auth && auth.userName && role !== null && role !== ROLES.SITE_MANAGER ? true : false;  

    return(
        <div
         
            style={{
                paddingLeft:isAdmin ? '75px' :null,
                width:`${ !isAdmin ? window.innerWidth :window.innerWidth  >= 1445 ? window.innerWidth - 390  : window.innerWidth -90}px`,
                paddingRight:isAdmin ?'15px' :null,
          

                // paddingTop:60
            }}
        >
            {children}
        </div>
    );
}