import axios from 'axios';
import { serverHost} from '../../redux/reducers/reducer';

export default  async function RefreshToken(){
    const server =serverHost()
    axios.defaults.withCredentials= true
    try {
    const response = await axios.post(`${server}/user/token`)
    
    if (response.status === 200){
        const data = response.data.data
        const accessToken = data?.accessToken;
        axios.defaults.headers.common['authorization'] =`Bearer ${accessToken}`;
        const userId = data?.userId;
        const roles = data?.roles;
        const sites_list = data?.sites_list;
        const userName = data?.userName;
        const storage_site= localStorage.getItem('relevantSite');
        const relevant_site = storage_site && Array.isArray(sites_list)  && sites_list.length > 0 && sites_list.includes(storage_site) ? storage_site : sites_list[0] ? sites_list[0] : 'develop';
        const isManager = roles.includes("Manager");
        const isAdmin = roles.includes("Admin");
        const isOperator = roles.includes("Operator");
        const user ={   
          accessToken,
          roles,
          sites_list,
          userName,
          relevant_site,
          isManager,
          isOperator,
          isAdmin,
          userId
        };
        return user;
    }
      return (null)
    }catch(err){
        console.error(err.message)
        return null;
        }
}
