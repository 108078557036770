import React,{memo} from 'react'
import styles from "./event.module.css";


import common_types from '../../../common_files/common_types';

const EVENT_TYPES=common_types.event_type_enum;


function BottomActions({
    event_type,
    eventId,
    resolveEvent,
    cartId,
    focusOnCart,
    callBack,
    userId,
    assistCart,
    eventName,
    blockedImg,
    closeEvent,
    data,
    showMalfunctionModal,
    event

}) {


        const ResolvedButton =()=>{
            return(
                <div
                    onClick={(e)=>{
                        // e.stopPropagation();
                        // closeEvent();
                        resolveEvent(eventId);
                    }}
                    className={styles.resolvedButton}>
                </div>
            );
        }
    
        const FocusOnCart =()=>{
            return(
                <div 
                    onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                        closeEvent();
                        if(cartId){
                            focusOnCart(cartId); 
                        }
                    }}
                    className={styles.focusOnCartButton}>
    
                </div>
            );
        }
    
       
    
        const CallBack = ()=>{
            return(
                <div 
                    onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();

                        callBack(userId);
                    }}
                    className={styles.callBackButton}>
                </div>
            );
        };
    

        const AssistCart =()=>{
            return(
                <div
                    onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                        assistCart(cartId,eventId,eventName,blockedImg);
                    }}
                    className={styles.assistCartButton}>
                </div>
            );
        };


        const ShowMalfunctionData =()=>{
            return(
                <div
                    onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();

                        //JUST FOR DEBUG - REMEBER TO REMOVE
                        // assistCart(cartId,eventId,eventName,null);
                        showMalfunctionModal(event);
                    }}
                    className={styles.assistCartButton}
                />
            );
        };
        

    return(
        <>
        {
                event_type === EVENT_TYPES.ROAD_EVENT 
            ?
                <div className={styles.event_item_open_actions} >
                    <AssistCart />
                    <FocusOnCart />
                    <ResolvedButton />
                </div>
            :
                event_type === EVENT_TYPES.MISSED_CALL_EVENT 
            ?
                <div className={styles.event_item_open_actions} >
                    <CallBack />
                    <ResolvedButton />
                </div>
            :
                event_type === EVENT_TYPES.MULFUNCTION_EVENT 
            ?
                <div className={styles.event_item_open_actions} >
                    <ShowMalfunctionData/>
                    <FocusOnCart />
                    <ResolvedButton />
                </div>
            :
               
                <div className={styles.event_item_open_actions} >
                    <ResolvedButton />
                </div>

        }
           
        </>
    );
}   


export default memo(BottomActions);