import axios from "axios";

/**
 * Retrieves map data for multiple sites with a prioritized relevant site.
 * 
 * @param {string[]} site_list - An array of site names.
 * @returns {Promise<object>} - An object containing map data for each site.
 */
export const getMapDataObjectFromAllSites = async (site_list,setSitesObjectState) => {

    const fetchSiteData = async (site, attempt = 1) => {
        let url = site !== 'localhost' ? `https://${site}.carteav.com` : "http://localhost:8080";
        try {
            const response = await axios.get(url + `/admin/get_map_data`);
            if (response.data !== 'err') {
                return response.data;
            }
            return null;
        } catch (error) {
            console.error(`Failed to get map data from site ${site} after ${attempt} attempts: ${error.message}`);
            return null;
        }
    };

    const addSite = (siteName, siteData) => {
        setSitesObjectState(prevState => ({
            ...prevState,
            [siteName]: siteData
        }));
    };

    const handleResponse = (sitePromise, site) => {
        sitePromise
            .then((data) => {
                console.log(`Processed data from site ${site}`);
                addSite(site,data);
            })
            .catch(error => {
                console.log(`Failed to process data from site ${site}: ${error.message}`);
                addSite(site,null);
            });
    };

    site_list.forEach(site => {
        const sitePromise = fetchSiteData(site);
        handleResponse(sitePromise, site);
    });


};
