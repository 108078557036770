import './customModal.css';



const renderCustomModalFunction = ({
    id,
    title,
    text,
    icon,
    className=null,
    buttons,
    dangerMode = false,
    confirmButtonText = "OK",
    closeOnConfirm = true,
    closeOnOutsideClick = true,
    performOperatorBreakCallback,
    bodyText,
}) => {
    return new Promise((resolve, reject) => {
        // Create background overlay
        const overlay = document.createElement('div');
        overlay.classList.add('modal-overlay');

        // Render your custom modal using the provided parameters
        const modalContainer = document.createElement('div');
        modalContainer.classList.add(`custom-modal`);
        
        if(className){
            modalContainer.classList.add(className);
        }
        

        modalContainer.innerHTML = `
            <div class="customModalBody">
                <div class="customModalHeader">${title}</div>
                <div class="customModalSeperator"></div>
                <div class="customModalBodyText">${bodyText}</div>
                <div class="customModalButtons">
                    ${buttons.map(button => `
                        <div class="customModalButton ${button.class}"  data-value="${button.value}">
                            ${button.icon ? `<i class="${button.icon}"></i>` : ''} 
                            ${button.text}
                        </div>`
                    ).join('')}
                </div>
            </div>
        `;

        modalContainer.id = id;

        buttons.forEach((button, index) => {
            modalContainer.querySelectorAll('.customModalButton')[index].addEventListener('click', () => {
                if (button.closeModal !== false) {
                    closeModal(false);
                }
                console.log('BUTTON');
                if (button.callback) {
                    button.callback(true);
                }


                resolve({ value: button.value });
            });
        });

        // Function to close the modal and remove overlay
        const closeModal = (triggerCancel=true) => {
            if (buttons && Array.isArray(buttons) && triggerCancel) {
                const closeButton = buttons.find(button =>  button.value === "cancel")
                if(closeButton && closeButton.callback){
                    closeButton.callback(true);
                }
            }

            modalContainer.remove();
            overlay.remove();
        };

        // Close modal on outside click if enabled
        if (closeOnOutsideClick) {
            overlay.addEventListener('click', (event) => {
                if (event.target === overlay) {
                    closeModal();
                    resolve({ value: null });
                }
            });
        }

        // Append modal and overlay to the body
        document.body.appendChild(overlay);
        document.body.appendChild(modalContainer);
    });
}

export default renderCustomModalFunction;


// Function to trigger click on modal overlay if visible
export const triggerModalOverlayClick = (modalContainerId) => {
    const modalContainer = document.getElementById(modalContainerId);
    if (modalContainer) {
        const buttonsContainer = modalContainer.getElementsByClassName("customModalButtons")[0]; // Get the first element with the class 'customModalButtons'

        if (buttonsContainer) {
            const buttons = buttonsContainer.getElementsByClassName("customModalButton"); // Get all buttons inside the container
            // Optionally, iterate over the buttons or perform actions
            Array.from(buttons).forEach(button => {

                const value = button.getAttribute('data-value');
                if( value && value=== 'cancel'){

                    //trigger click
                    button.click(false);
                }

            });
        }
    }
};