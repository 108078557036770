import React, { useEffect, useState } from 'react';
import styles from './cartCameras.module.css'
import useStateAndRef from '../../hooks/useStateAndRef';
import useAuth from '../../hooks/Auth/useAuth';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Box, Modal } from '@mui/material';
import LaodingDialog from '../dialogs/laoding';


const camerasList = [

  {
    camera_name: 'left',
    show: true,
  },
  {
    camera_name: 'front',
    show: true,
  },
  {
    camera_name: 'right',
    show: true,
  },
  {
    camera_name: 'incabin',
    show: true,
  },
];

function CartCameras({ cartId, handleClose }) {
  const { relevantUrl, relevantSite, sitesSockectsConnection, auth } = useAuth();
  const [cameras, setCameras,refCameras] = useStateAndRef([]);
  const [enlargedCamera, setEnlargedCamera,refEnlargedCamera] = useStateAndRef(null);
  const [anotherAdminAlredyRequestCamera,setAnotherAdminAlredyRequestCamera,refAnotherAdminAlredyRequestCamera] = useStateAndRef(false);
  const [loading,setLoading] = useState(true);
  const [requestCamerasNotSucceeded,setRequestCamerasNotSucceeded]= useState(false);



  // Function to handle enlarging a camera view
  const enlargeCamera = (camera) => {
    setEnlargedCamera(camera);
  };

  // Function to handle closing the enlarged camera view
  const closeEnlargedCamera = () => {
    setEnlargedCamera(null);
  };

  // Function to toggle the camera state between on and off for a specific camera
  const toggleCameraState = (cameraName, currentState) => {
    const updatedCameras = cameras.map((camera) =>
      camera.camera_name === cameraName ? { ...camera, show: !currentState } : camera
    );

    setCameras(updatedCameras); // Update the state locally
    requestCameras(updatedCameras); // Request cameras with updated state
  };

  // Function to handle receiving cameras data from the socket
  const handleSocketData = (data) => {
    
    if(loading){
      setLoading(false);
      if(requestCamerasNotSucceeded){
        setRequestCamerasNotSucceeded(false);
      }
    }
    
    const { cart_number, cameras_data_list } = JSON.parse(data);
    if (cameras_data_list && Array.isArray(cameras_data_list)) {
      const updatedCameras = mergeCameras(cameras_data_list);
      setCameras(updatedCameras);
    }
  };

  useEffect(() => {
    if (relevantSite && sitesSockectsConnection && sitesSockectsConnection[relevantSite]) {
      const relevantSiteSocket = sitesSockectsConnection[relevantSite];
      relevantSiteSocket.on('cartCamerasData', handleSocketData);
      return () => {
        relevantSiteSocket.emit('sm_turn_off_cameras_event', cartId);
        relevantSiteSocket.off('cartCamerasData');
      };
    }
  }, [sitesSockectsConnection, relevantSite]);

  // when component loaded, request the cameras once
  useEffect(() => {
    requestCamerasFirstTime();
  }, []);

  const requestCamerasFirstTime  =()=>{
    if(!loading){
      setLoading(true);
    }
    if(requestCamerasNotSucceeded){
      setRequestCamerasNotSucceeded(false); //
    }
    requestCameras(camerasList,true);

  }

  useEffect(() => {
    const closeAfterThreeSeconds = setTimeout(() => {
      if (loading) {
        setRequestCamerasNotSucceeded(true); 
        setLoading(false);
      }
      
    }, 10000); 
  
    return () => {
      clearTimeout(closeAfterThreeSeconds);
    };
  }, [loading]); 

  // Function to request the cameras from the server
  const requestCameras = (updatedCameras ,isFirstRequest=false) => {


    const cameraCommandsList =isFirstRequest ?updatedCameras :  generateCameraCommandsList(updatedCameras);
    axios
      .post(relevantUrl + `/admin/requestCameras`, {
        camera_commands_list: cameraCommandsList,
        cartId: cartId,
        adminId: auth && auth.userId ? auth.userId : null,
      })
      .then((res) => {
        const response = res.data;
        if (response.data === 'ok') {
          anotherAdminAlredyRequestCamera && setAnotherAdminAlredyRequestCamera(false)
          return;
        };
        if(response.data ==='another_admin_alreay_request'){
          setAnotherAdminAlredyRequestCamera(true);
        }else{
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong',
              position: 'center',
              confirmButtonColor: '#00A7EE',
            });
          }
      });
  };


const generateCameraCommandsList = (updatedCameras) => {
    return updatedCameras
      .filter((camera) => camera.show) // Filter out cameras with show: false
      .map((camera) => ({
        camera_name: camera.camera_name,
        show: true,
        interval_msec: camera.interval_msec,
        resolution: camera.resolution,
      }));
  };


  // Function to merge the received cameras with the full cameras list
  const mergeCameras = (receivedCameras) => {
    const camerasMap = {};
    receivedCameras.forEach((camera) => {
        camerasMap[camera.camera_name] = camera;
    });

   const currentCameras = refCameras?.current;
   const currentCamerasMap = {};
   if(Array.isArray(currentCameras)) {
      currentCameras.forEach((camera) => {
      currentCamerasMap[camera.camera_name] = camera;
    });

   }
    return camerasList.map((camera) => {
        const receivedCamera = camerasMap[camera.camera_name];
        
        const currentCameraIsShowing = currentCamerasMap[camera.camera_name] && currentCamerasMap[camera.camera_name].show === true; 
        if(currentCameraIsShowing && !receivedCamera && !refAnotherAdminAlredyRequestCamera.current){
          camera =currentCamerasMap[camera.camera_name];
          return camera;
        }
        if (receivedCamera || (currentCameraIsShowing && !refAnotherAdminAlredyRequestCamera.current)) {
            camera.show = true;
            camera.image_base64 = receivedCamera?.image_base64;
            if (refEnlargedCamera && refEnlargedCamera.current && refEnlargedCamera.current !== null && refEnlargedCamera.current.camera_name === camera.camera_name) {
                setEnlargedCamera(camera);
            }
        } else {
            camera.show = false;
            camera.image_base64 = null;
        }
        return camera;
    });
  };


  const maxEnlargedCameraWidth = Math.min(1000, window.innerWidth * 0.9);
  const maxEnlargedCameraHeight = Math.min(800, window.innerWidth * 0.9);


  const enlargedCameraStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    outline: 'none',
    width: `${maxEnlargedCameraWidth}px`,
    height:`${maxEnlargedCameraHeight}px`,
    margin: 'auto',
    overflow: 'auto',
  };



  return (
    <div style={{ width: `${maxEnlargedCameraWidth}px` }}>
      {
          requestCamerasNotSucceeded 
        ? 
        <div className={styles.requestCamerasNotSucceeded_text} >
          Request Camera Not Succeeded 
          <button onClick={()=>{
            requestCamerasFirstTime();
          }}>
            Try Again
          </button>
        </div>
        : 
          loading 
            ? 
              <div style={{marginBottom:'15px'}}>
                <LaodingDialog /> 
                </div>
            :
              <>
                { /* Display the list of cameras */ }
                <div className={styles.anotherAdminAlredyRequestCamera_text} >
                  {anotherAdminAlredyRequestCamera && <span>Pay attention: another admin already request cart camera, you can't control cameras. </span>}
                </div>
                <div className={styles.cameras_container}>
                  {cameras.map((camera, index) => (
                    <div key={index} className={styles.camera_item}>
                      <h3>{camera.camera_name}</h3>
                      <span className={styles.camera_img_container}>
                        {camera.show === true && camera.image_base64 && <img src={`data:image/jpeg;base64,${camera.image_base64}`} alt={`Camera ${index}`} />}
                      </span>
                      {/* Option to turn off or on the camera based on its current state */}
                      <div className={styles.camera_item_buttons_container}>
                        {!anotherAdminAlredyRequestCamera &&
                          <button className={styles.camera_item_button}
                            onClick={() => toggleCameraState(camera.camera_name, camera.show)}
                            style={{
                              color: camera.show === true && camera.image_base64 ? 'var(--grey)' : null
                            }}
                          >
                            Turn {camera.show === true && camera.image_base64 ? 'Off' : 'On'}
                          </button>}
                        <button disabled={!(camera.show === true && camera.image_base64)} onClick={() => enlargeCamera(camera)}>Enlarge</button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
    }
     {/* Enlarged camera view */}
     <Modal 
      open={enlargedCamera !== null} 
      onClose={closeEnlargedCamera}
      style={{ position:'fixed',display: 'flex', justifyContent: 'center', alignItems: 'center' }}
     >   
        <Box sx={enlargedCameraStyles}>
          {enlargedCamera && (
            <>
              <h2>{enlargedCamera.camera_name}</h2>
              <img style={{height:'80%',width:'90%'}} src={`data:image/jpeg;base64,${enlargedCamera.image_base64}`} alt="Enlarged Camera" />
            </>
          )}
          <button style={{marginTop:'20px',color:'var(--grey)'}} onClick={closeEnlargedCamera}>Close</button>
        </Box>
      </Modal>
    </div>
  );
}

export default CartCameras;
