import React, { useState , useEffect } from 'react';
import styles from "./event.module.css"
import useAuth from '../../../hooks/Auth/useAuth.js';
import { ROLES } from '../../../redux/context/allowedTypes.js';
import SearchBar from "material-ui-search-bar";
import EventItem from './eventItem.js';
import axios from 'axios';
import VoiceCall from '../../vocieCall/voice.js';
import { useHistory } from "react-router-dom";
import BlockEventDialog from "./blockEventDialog";
import MalfunctionEventDialog from './malfunctionEventDialog';


export default function Event({sitesEventObject,setSitesEventObject,eventsContainerOpen, setEventsContainerOpen,eventContainerRef}) {
    
    let {sitesSockectsConnection ,relevantSite ,role,auth,relevantUrl } = useAuth();
    let [openDialog,setOpenDialog] = useState(false);
    let [openMalfuntionDialog,setOpenMalfuntionDialog] = useState(false);
    const [malfunctionDialogData,setMalfunctionDialogData] = useState(null);
    let [assisCartEventObject,setAssistCartEventObject] = useState(null);
    const [eventSearchVal,setEventSearchVal] = useState("");
    const history = useHistory();
 
    const toggleEventsContainer = () => {
        setEventsContainerOpen(prevState => !prevState);
    };


    const closeMalfunctionDialog =()=> {
        setOpenMalfuntionDialog(false);
        setMalfunctionDialogData(null);
    }
 

    const requestEventSearch =(p_searchVal)=>{

    }
    
    const cancelEventSearch =()=>{
        setEventSearchVal("");
    }

    // in case of assist cart malfunction dialog open , should updae data in real time
    useEffect(()=>{
        if(openMalfuntionDialog && sitesEventObject){
            let updatedEvent= null;
            if(Object.keys(sitesEventObject).length > 0){
                for (const eventArray of Object.values(sitesEventObject)){
                    for (const eventItem of eventArray){
                        if(eventItem.eventId ===malfunctionDialogData.eventId ){
                            updatedEvent = eventItem;
                        }
                    }
                }
            }
            
            if(updatedEvent){
                setMalfunctionDialogData(updatedEvent);
            }else{
                setOpenMalfuntionDialog(false);
                setMalfunctionDialogData(null);
            }
        }
    },[openMalfuntionDialog,sitesEventObject])

    const assistCart =(cartId,eventId,eventName,blockedImg)=>{
        const newAssistCart ={
            cartId:cartId,
            eventId:eventId,
            eventName:eventName,
            blockedImg:blockedImg
        };
        
        setAssistCartEventObject(newAssistCart);
        setOpenDialog(true);
    }


    const showMalfunctionModal=(p_event)=>{
        
        setOpenMalfuntionDialog(true);
        setMalfunctionDialogData(p_event);
    }

    const resolveEvent =async (p_eventId)=>{

        // Find the category and event index
        let category, eventIndex;
        for (const key in sitesEventObject) {
            eventIndex = sitesEventObject[key].findIndex(event => event.eventId === p_eventId);
            if (eventIndex !== -1) {
                category = key;
                break;
            }
        }

        if (category !== undefined && eventIndex !== -1) {
            // Create a copy of the state
            const newSitesEventObject = { ...sitesEventObject };
            const [removedEvent] = newSitesEventObject[category].splice(eventIndex, 1);

            // Update the state
            setSitesEventObject(newSitesEventObject);

            try {
                axios.post(relevantUrl+"/admin/deleteEvent",{eventId:p_eventId})
                    
            } catch (error) {
                // If the request fails, add the event back to the sitesEventObject
                newSitesEventObject[category].splice(eventIndex, 0, removedEvent);
                setSitesEventObject(newSitesEventObject);
            }
        }


     
    }

    const removeEventFromList = (p_eventId)=>{
        console.log(p_eventId);
        console.log(sitesEventObject)
    }

    const focusOnCart =(p_cartId)=>{
        history.push({
            pathname: "/dashboard_site",
            state:{  cartID :p_cartId}
          });
        return;
    }

    const callBack =(userId)=>{

        if(sitesSockectsConnection && relevantSite){
            
            const relevant_socket = sitesSockectsConnection[relevantSite];
            
            if(relevant_socket){
                relevant_socket.emit('getUserDetails',userId);
            }
        }else{
            console.log("Call Back failed ",sitesSockectsConnection ," ", relevantSite);
        }
    }

  

    return (
        <div>
            <VoiceCall />
       
            {
                    auth && auth.userName &&  role && role !== ROLES.SITE_MANAGER  && eventsContainerOpen
                ?
                    <div 
                        className={styles.events_alerts_body_open } 
                     
                        ref={eventContainerRef}
                    >
                       
                        
                        
                        <div className={styles.cotainer_header}>
                            <div className={styles.center_row}
                                style={{ width: '150px', borderBottom: '2px solid #070A0E' }}
                            >
                                Alerts
                                <span className={styles.events_number_circle}>
                                    {sitesEventObject && sitesEventObject[relevantSite] ?
                                        sitesEventObject[relevantSite].length
                                        : 0}
                                </span>
                            </div><div className={styles.center_row}
                                style={{ width: '150px', borderBottom: '2px solid #CED5EF' }}
                            >
                                    Chat
                                    <span className={styles.events_number_circle}>
                                        0
                                    </span>
                        </div>
                        </div>

                        <div className={styles.search_bar}>
                            <SearchBar
                                style={{border:eventSearchVal !== "" ? '1px solid #00A7EE':'1px solid #CED5EF',boxShadow:'none',width:'100%'}}
                                value={eventSearchVal}
                                onChange={(searchVal) => requestEventSearch(searchVal)}
                                onCancelSearch={() => cancelEventSearch()}
                            />
                        </div>
                        <div >
                            { 
                                    sitesEventObject && sitesEventObject[relevantSite] 
                                ? 
                                    sitesEventObject[relevantSite].map((event,index)=>(
                                    <EventItem 
                                            resolveEvent={resolveEvent} 
                                            key={`event-item-${event.event_name}-${index}`} 
                                            event={event}
                                            focusOnCart={focusOnCart}
                                            callBack={callBack}
                                            assistCart={assistCart}
                                            showMalfunctionModal={showMalfunctionModal}
                                        />
                                    ))
                                : 
                                    null                                    
                            }
                            
                        </div>
                    </div>
                : 
                    null
            }
            {       
                    assisCartEventObject 
                ?  
                    <BlockEventDialog 
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        assisCartEventObject={assisCartEventObject}
                        setAssistCartEventObject={setAssistCartEventObject}
                    />
                :
                    null
            }

            {
                    openMalfuntionDialog 
                ?
                    <MalfunctionEventDialog
                        openDialog={openMalfuntionDialog}
                        closeDialogFunction={closeMalfunctionDialog}
                        data={malfunctionDialogData}
                    />
                :
                    null
            }
        </div>
    );
}
